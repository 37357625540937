
















import _ from 'lodash'
import ECharts from 'vue-echarts/components/ECharts.vue'
import moment, {Moment} from 'moment'
import {Component, Mixins, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {Contract} from '@/model/resource/Contract'
import {CacheAssist} from '@/app/CacheAssist'

type Serie = {
  data: any[]
  type: string
}

type Stats = {
  date: Moment
  transactionCount: number
}

@Component({
  components: {ECharts},
})
export default class ContractsChart extends Mixins(MixinScreenSize) {
  @Prop({type: Contract, required: true}) contract!: Contract

  daysToRender = 15

  cache = new CacheAssist(
    this.daysToRender,
    this.contract.dapp?.idDappPk,
    this.contract?.hash
  )

  get serie() {
    return this.xAxisData.reduce(
      (serie: Serie, currentDate: Moment) => {
        const item = this.normalizedData.find(
          ({date}) => moment(date).dayOfYear() === currentDate.dayOfYear()
        ) as Stats

        return {
          ...serie,
          data: [
            ...serie.data,
            {
              value: item?.transactionCount || 0,
              itemStyle: {
                color: 'transparent',
              },
              symbolSize: 0,
            },
          ],
        }
      },
      ({
        data: [],
        type: 'line',
        lineStyle: {
          width: 3,
        },
      } as unknown) as Serie
    )
  }

  get xAxisData(): Moment[] {
    const initialDate = moment().subtract(this.daysToRender, 'days')
    // The function (daysToRender -1) is to remove the present day to the graphs
    return Array.from({length: this.daysToRender - 1}).map(() => {
      const nextDate = initialDate.add(1, 'day')
      return _.cloneDeep(nextDate)
    })
  }

  get normalizedData() {
    const lastMonth = moment().subtract(this.daysToRender, 'days')

    return [
      ...this.cache.contractDailyCacheList,
      ...this.cache.dappDailyCacheList,
    ]
      .map(item => ({
        date: moment(item.date),
        transactionCount: item.transactionCount,
      }))
      .filter(item => lastMonth.isSameOrBefore(item.date)) as Stats[]
  }

  get getColorStops() {
    let positive = [
      {
        offset: 0,
        color: '#77fbaf', // color at 0% position
      },
      {
        offset: 1,
        color: '#134d89', // color at 100% position
      },
    ]
    let negative = [
      {
        offset: 0,
        color: '#fb77d5', // color at 0% position
      },
      {
        offset: 1,
        color: '#841706', // color at 100% position
      },
    ]
    if (this.cache.percentageGrow >= 0) return positive
    else return negative
  }

  get options() {
    return {
      xAxis: {
        type: 'category',
        data: this.xAxisData.map(item => item.date()),
        show: false,
      },
      yAxis: {
        type: 'value',
        show: false,
      },
      series: this.serie,
      color: {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: this.getColorStops,
        global: false, // false by default
      },
    }
  }

  async created() {
    await this.$await.run(
      `getDataChart_${this.contract.idContractPk}`,
      async () => {
        await this.contract.getContract(this.contract.idContractPk)
        await this.cache.populate()
      }
    )
  }
}
