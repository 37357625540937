



































































































































































































































import {Component, Watch} from 'vue-property-decorator'
import NavbarMenu from '@/components/NavbarMenu.vue'
import AppFooter from '@/components/AppFooter.vue'
import FilterToggle from '@/components/FilterToggle.vue'
import NavHeaderMenu from '@/components/NavHeaderMenu.vue'
import {ContractCollection} from '@/model/collection/ContractCollection'
import {SimpleListContractSchema} from '@/schema/resource/Contract/SimpleListContractSchema'
import {NeoFeed} from '@/app/NeoFeed'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'
import FilterContractClient, {
  getSortOptions,
  SortOption,
} from '@/components/filters/FilterContractClient.vue'
import {Dapp} from '@/model/resource/Dapp'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import ContractsChart from '@/components/ContractsChart.vue'
import {MetaInfo} from 'vue-meta'
import {CacheAssist} from '@/app/CacheAssist'
import DappDetailedStatsChart from '@/components/dappDetailed/DappDetailedStatsChart.vue'
import DappDetailedSummary from '@/components/dappDetailed/DappDetailedSummary.vue'
import DappsHeader from '@/components/DappsHeader.vue'
import {DappDetailedGraphPeriod} from '@/enums/DappDetailedGraphPeriod'
import {DappInfo} from '@/model/resource/DappInfo'

@Component({
  components: {
    DappDetailedStatsChart,
    DappDetailedSummary,
    ContractsChart,
    FilterContractClient,
    PercentageProgress,
    NavbarMenu,
    NavHeaderMenu,
    FilterToggle,
    AppFooter,
    DappsHeader,
  },
})
export default class ContractsView extends MixinScreenSize {
  collection = new ContractCollection()
  schema = new SimpleListContractSchema()

  idExpanded: number | null = null
  cache: CacheAssist | null = null

  daysRendered = DappDetailedGraphPeriod.SEVEN_DAYS

  feed = new NeoFeed()
  dapp = new Dapp()
  dappInfo = new DappInfo()

  private sorterOptions = getSortOptions()
  sorterField: SortOption = this.sorterOptions[5]

  async created() {
    this.applyCollection()
    await this.populateResources()
  }

  applyCollection() {
    this.collection.perPage = 10
    this.collection.private = false

    this.schema.isMobile = this.isMobile
  }

  async populateResources() {
    const promises: Array<Promise<unknown>> = [
      this.collection.expand(),
      this.feed.populate(),
    ]

    await Promise.all(promises)
  }

  @Watch('idExpanded')
  async expandEvent(idContractPk: number | null) {
    const contract =
      this.collection.items.find(it => it.$id === idContractPk) ?? null

    await this.$await.run(`getDapp_${contract?.idContractPk}`, async () => {
      if (contract?.dapp) {
        await this.dapp.getDapp(contract.dapp.$id)

        const promises: Array<Promise<unknown>> = [
          this.dappInfo.getInfoDapp(this.dapp.slug ?? ''),
        ]

        await Promise.all(promises)

        if (this.isMobile) {
          this.cache = new CacheAssist(
            this.daysRendered,
            this.dapp.idDappPk,
            contract.hash
          )
          await this.cache.populate()
        }
      }
    })
  }

  metaInfo(): MetaInfo {
    return {
      title: this.$translate('metadata.title.contracts'),
    }
  }

  filterItems(list: string[]) {
    return list.filter(item =>
      this.isMobile ? !['title', 'hash'].includes(item.toLowerCase()) : true
    )
  }

  onOrderBy() {
    const findSorterField = this.sorterOptions.find(
      ({field, asc}) =>
        field == this.collection.orderBy && asc === this.collection.asc
    )

    if (findSorterField) {
      this.sorterField = findSorterField
    }
  }

  headerName(value: string, key: number) {
    if (key === 1 && this.isMobile) {
      return this.schema.translateFrom('dapp')
    }
    if (this.isMobile) {
      return [0].includes(key) ? '' : value
    }

    return value
  }

  headerClass(key: number) {
    if (this.isMobile) {
      if (key === 0) {
        return 'px-0'
      }
      if (key === 3 || key === 2) {
        return 'hidden'
      }
    }

    return null
  }

  rowClass(field: string) {
    if (field === 'rank' && this.isMobile) {
      return 'px-0'
    }

    return null
  }

  isExpanded(idContractPk: number) {
    return this.idExpanded === idContractPk
  }

  toggleExpand(idContractPk: number) {
    if (this.isExpanded(idContractPk)) {
      this.idExpanded = null
    } else {
      this.idExpanded = idContractPk
    }
  }
}
