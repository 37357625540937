/**
 * DappHourlyCacheCollection
 * @author Lopes
 */
import {HttpExclude, Request, RequestExpose} from '@simpli/serialized-request'
import {PageCollection} from '@simpli/resource-collection'
import {DappCollection} from '@/model/collection/DappCollection'
import {DappHourlyCache} from '@/model/resource/DappHourlyCache'

@HttpExclude()
export class DappHourlyCacheCollection extends PageCollection<DappHourlyCache> {
  resource?: IDappHourlyCacheCollectionResourcesHolder

  @RequestExpose() idDappFk: number | null = null
  @RequestExpose() startDate: string | null = null
  @RequestExpose() endDate: string | null = null

  constructor() {
    super(DappHourlyCache)
  }

  queryAsPage() {
    return this.listDappHourlyCache()
  }

  async listDappHourlyCache() {
    return await Request.get(`/client/dapp-hourly-cache`, {params: this.params})
      .name('listDappHourlyCache')
      .as(this)
      .getResponse()
  }
}

export interface IDappHourlyCacheCollectionResourcesHolder {
  collectionDapp: DappCollection
}
