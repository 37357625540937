import {DappDailyCache} from '@/model/resource/DappDailyCache'
import {ContractDailyCache} from '@/model/resource/ContractDailyCache'
import moment from 'moment'
import {ContractHourlyCache} from '@/model/resource/ContractHourlyCache'
import {DappHourlyCache} from '@/model/resource/DappHourlyCache'
import {DappDetailedGraphPeriod} from '@/enums/DappDetailedGraphPeriod'

export class CacheAssist {
  contractDailyCacheMap: Map<string, ContractDailyCache[]> = new Map()
  dappDailyCacheMap: Map<string, DappDailyCache[]> = new Map()

  contractDailyCacheList: ContractDailyCache[] = []
  dappDailyCacheList: DappDailyCache[] = []

  contractHourlyCacheMap: Map<string, ContractHourlyCache[]> = new Map()
  dappHourlyCacheMap: Map<string, DappHourlyCache[]> = new Map()

  contractHourlyCacheList: ContractHourlyCache[] = []
  dappHourlyCacheList: DappHourlyCache[] = []

  idDapp: number | null = null
  hash: string | null = null

  daysRendered: number
  daysFetched: number

  period: DappDetailedGraphPeriod = DappDetailedGraphPeriod.SEVEN_DAYS

  constructor(
    period: DappDetailedGraphPeriod,
    idDapp?: number | null,
    hash?: string | null
  ) {
    this.idDapp = idDapp ?? null
    this.hash = hash ?? null
    this.period = period
    this.daysRendered = period
    this.daysFetched = period * 2 // We need to fetch the double of days to compare the growth
  }

  get allHourlyCache() {
    return [...this.contractHourlyCacheList, ...this.dappHourlyCacheList]
  }

  get allDailyCache() {
    return [...this.contractDailyCacheList, ...this.dappDailyCacheList]
  }

  get transactionCount() {
    if (this.period === DappDetailedGraphPeriod.ONE_DAY) {
      return this.calculateTransactionCount24h()
    }

    return this.calculateTransactionCountDays()
  }

  get percentageGrow() {
    if (this.period === DappDetailedGraphPeriod.ONE_DAY) {
      return this.calculatePercentageGrow24h()
    }

    return this.calculatePercentageGrowDays()
  }

  async populate() {
    this.clearLists()

    const startDate = moment()
      .subtract(this.daysFetched, 'days')
      .format()

    if (this.hash) {
      await this.populateByHash(this.hash, startDate)
      return
    }

    if (this.idDapp) {
      await this.populateByDappId(this.idDapp.toString(), startDate)
    }
  }

  private async populateByDappId(idDapp: string, startDate: string) {
    if (this.period === DappDetailedGraphPeriod.ONE_DAY) {
      await this.populateByDappIdHourly(idDapp, startDate)
      return
    }

    await this.populateByDappIdDaily(idDapp, startDate)
  }

  private async populateByDappIdHourly(idDapp: string, startDate: string) {
    const itemFoundInCache = this.dappHourlyCacheMap.get(idDapp)

    if (itemFoundInCache) {
      this.dappHourlyCacheList = itemFoundInCache
      return
    }

    const {items} = await DappHourlyCache.listDappHourlyCache({
      idDappFk: idDapp,
      startDate,
    })

    this.dappHourlyCacheMap.set(idDapp, items)
    this.dappHourlyCacheList = items
  }

  private async populateByDappIdDaily(idDapp: string, startDate: string) {
    const itemFoundInCache = this.dappDailyCacheMap.get(idDapp)

    if (itemFoundInCache) {
      this.dappDailyCacheList = itemFoundInCache
      return
    }

    const {items} = await DappDailyCache.listDappDailyCache({
      idDappFk: idDapp,
      startDate,
    })

    this.dappDailyCacheMap.set(idDapp, items)
    this.dappDailyCacheList = items
  }

  private async populateByHash(hash: string, startDate: string) {
    if (this.period === DappDetailedGraphPeriod.ONE_DAY) {
      await this.populateByHashHourly(hash, startDate)
      return
    }

    await this.populateByHashDaily(hash, startDate)
  }

  private async populateByHashHourly(hash: string, startDate: string) {
    const itemFoundInCache = this.contractHourlyCacheMap.get(hash)

    if (itemFoundInCache) {
      this.contractHourlyCacheList = itemFoundInCache
      return
    }

    const items = await ContractHourlyCache.listContractHourlyCache({
      hash,
      startDate,
    })

    this.contractHourlyCacheMap.set(hash, items)
    this.contractHourlyCacheList = items
  }

  private async populateByHashDaily(hash: string, startDate: string) {
    const itemFoundInCache = this.contractDailyCacheMap.get(hash)

    if (itemFoundInCache) {
      this.contractDailyCacheList = itemFoundInCache
      return
    }

    const items = await ContractDailyCache.listContractDailyCache({
      hash,
      startDate,
    })

    this.contractDailyCacheMap.set(hash, items)
    this.contractDailyCacheList = items
  }

  private calculateTransactionCountReference24h() {
    const startDate = moment()
      .add(-1, 'days')
      .format()

    const endDate = moment()
      .add(-1, 'days')
      .endOf('day')
      .format()

    return this.sumTransactionCountOfRange24h(startDate, endDate)
  }

  private calculateTransactionCountReferenceDays() {
    const startDateRemovingToday = moment()
      .subtract(this.daysFetched + 1, 'days')
      .format()

    const endDateIncreasingADay = moment()
      .subtract(this.daysFetched + 1, 'days')
      .format()

    return this.sumTransactionCountOfRangeDays(
      startDateRemovingToday,
      endDateIncreasingADay
    )
  }

  private calculatePercentageGrow24h() {
    const transactionCount = this.calculateTransactionCount24h()
    const transactionCountReference = this.calculateTransactionCountReference24h()

    return this.calculatePercentageGrow(
      transactionCount,
      transactionCountReference
    )
  }

  private calculatePercentageGrowDays() {
    const allDays = this.daysFetched + 1 // We need to compare the daysToFetch + 1 because we need to ignore the first day (today)
    if (this.allDailyCache.length < allDays) {
      return 100
    }

    const transactionCount = this.calculateTransactionCountDays()
    const transactionCountReference = this.calculateTransactionCountReferenceDays()

    return this.calculatePercentageGrow(
      transactionCount,
      transactionCountReference
    )
  }

  private calculatePercentageGrow(
    transactionCount: number,
    transactionCountReference: number
  ) {
    const growth =
      ((transactionCount - transactionCountReference) /
        transactionCountReference) *
      100

    if (isNaN(growth)) {
      return 0
    }

    if (!isFinite(growth)) {
      return 100
    }

    return growth
  }

  private calculateTransactionCount24h() {
    const startDate = moment()
      .subtract(24, 'hours')
      .format()

    const endDate = moment().format()

    return this.sumTransactionCountOfRange24h(startDate, endDate)
  }

  private calculateTransactionCountDays() {
    const startDate = moment()
      .subtract(this.daysRendered + 1, 'days')
      .format()

    const endDate = moment()
      .subtract(1, 'days')
      .format()

    return this.sumTransactionCountOfRangeDays(startDate, endDate)
  }

  private clearLists() {
    this.dappDailyCacheList = []
    this.contractDailyCacheList = []
    this.dappHourlyCacheList = []
    this.contractHourlyCacheList = []
  }

  private sumTransactionCountOfRange24h(startDate: string, endDate: string) {
    const items = this.allHourlyCache

    if (!items.length) {
      return 0
    }

    let acc = 0

    items.forEach(item => {
      const asMoment = moment(item.createdAtHourStart).startOf('hours')

      if (!asMoment.isBefore(startDate) && !asMoment.isAfter(endDate)) {
        acc += item.transactionCount || 0
      }
    })

    return acc
  }

  private sumTransactionCountOfRangeDays(startDate: string, endDate: string) {
    const items = this.allDailyCache

    if (!items.length) {
      return 0
    }

    let acc = 0

    items.forEach(item => {
      const asMoment = moment(item.date).startOf('day')
      if (!asMoment.isBefore(startDate) && !asMoment.isAfter(endDate)) {
        acc += item.transactionCount || 0
      }
    })

    return acc
  }
}
