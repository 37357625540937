


































































import _ from 'lodash'
import ECharts from 'vue-echarts/components/ECharts.vue'
import moment, {Moment} from 'moment'
import {Component, Mixins, Prop} from 'vue-property-decorator'
import {$} from '@/facade'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {DappDetailedGraphPeriod} from '@/enums/DappDetailedGraphPeriod'
import {CacheAssist} from '@/app/CacheAssist'

type Serie = {
  data: any[]
  type: string
}

type Stats = {
  date: Moment
  transactionCount: number
}

@Component({
  computed: {
    DappDetailedGraphPeriod() {
      return DappDetailedGraphPeriod
    },
  },
  components: {ECharts},
})
export default class DappDetailedStatsChart extends Mixins(MixinScreenSize) {
  @Prop({type: Object, required: true}) cache!: CacheAssist

  get doraUrl() {
    return $.env.DORA_URL
  }

  get serie() {
    const serie: Serie = {
      data: [],
      type: 'line',
      lineStyle: {
        width: 3,
      },
    } as Serie

    for (let i = 0; i < this.xAxisData.length; i++) {
      const legendDate = this.xAxisData[i]

      const stats: Stats = this.normalizedData.find(({date}) => {
        if (this.cache.period === DappDetailedGraphPeriod.ONE_DAY) {
          return moment(date).hour() === legendDate.hour()
        }

        return moment(date).dayOfYear() === legendDate.dayOfYear()
      }) ?? {date: legendDate, transactionCount: 0}

      serie.data.push({
        value: stats.transactionCount,
        itemStyle: {
          color: 'transparent',
        },
        emphasis: {
          itemStyle: {
            color: '#77fbaf',
          },
        },
        symbol: 'circle',
        symbolSize: 10,
        label: {},
      })
    }

    return serie
  }

  get xAxisData(): Moment[] {
    if (this.cache.period === DappDetailedGraphPeriod.ONE_DAY) {
      return this.calculateXAxisData24Hours()
    }

    return this.calculateXAxisDataForDays()
  }

  get normalizedData() {
    if (this.cache.period === DappDetailedGraphPeriod.ONE_DAY) {
      return this.cache.allHourlyCache.map(item => ({
        date: moment(item.createdAtHourStart),
        transactionCount: item.transactionCount,
      })) as Stats[]
    }

    const lastPeriod = moment().subtract(this.cache.daysRendered + 1, 'days')

    return this.cache.allDailyCache
      .map(item => ({
        date: moment(item.date),
        transactionCount: item.transactionCount,
      }))
      .filter(item => lastPeriod.isSameOrBefore(item.date)) as Stats[]
  }

  get options() {
    const xAxisDataFormatted = () => {
      if (this.cache.period === DappDetailedGraphPeriod.ONE_DAY) {
        return this.xAxisData.map(item => {
          const hour = parseInt(item.format('HH'))
          const suffix = hour >= 12 ? 'PM' : 'AM'
          const formattedHour = hour % 12 || 12

          return `${formattedHour} ${suffix}`
        })
      }

      return this.xAxisData.map(item =>
        item.format(
          this.$translate('components.DappDetailedStatsChart.monthDayFormat')
        )
      )
    }

    return {
      grid: {
        top: 24,
        bottom: 48,
        left: this.isMobile ? '15%' : '10%',
      },
      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => this.formatterTooltip(params),
        position: 'top',
      },
      xAxis: {
        type: 'category',
        data: xAxisDataFormatted(),
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          interval: this.intervalLabel,
          textStyle: {
            fontWeight: '500',
            color: '#82919c',
          },
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false,
        },
        splitLine: {
          lineStyle: {
            type: 'dashed',
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          textStyle: {
            fontWeight: '500',
            color: '#82919c',
          },
          formatter: (value: number) => {
            if (value >= 1000) {
              return `${value / 1000}k`
            }

            return value
          },
        },
      },
      series: this.serie,
      color: {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color: '#77fbaf', // color at 0% position
          },
          {
            offset: 1,
            color: '#134d89', // color at 100% position
          },
        ],
        global: false, // false by default
      },
    }
  }

  get intervalLabel() {
    if (this.isMobile) {
      switch (this.cache.period) {
        case DappDetailedGraphPeriod.ONE_DAY:
          return 5
        case DappDetailedGraphPeriod.SEVEN_DAYS:
          return 1
        case DappDetailedGraphPeriod.FOURTEEN_DAYS:
          return 3
        case DappDetailedGraphPeriod.THIRTY_DAYS:
          return 7
        default:
          return 0
      }
    }

    switch (this.cache.period) {
      case DappDetailedGraphPeriod.ONE_DAY:
        return 2
      case DappDetailedGraphPeriod.SEVEN_DAYS:
        return 0
      case DappDetailedGraphPeriod.FOURTEEN_DAYS:
        return 1
      case DappDetailedGraphPeriod.THIRTY_DAYS:
        return 4
      default:
        return 0
    }
  }

  formatterTooltip(params: any) {
    const [{dataIndex}] = params
    const transactionCount = this.serie.data[dataIndex]?.value || 0
    const date = this.xAxisData[dataIndex]
    const formattedDate = moment(date).format('DD/MM/YYYY')

    if (this.cache.period === DappDetailedGraphPeriod.ONE_DAY) {
      return this.formatTooltip24Hours(transactionCount, formattedDate, date)
    }

    return this.formatTooltipForDays(transactionCount, formattedDate)
  }

  private formatTooltip24Hours(
    transactionCount: number,
    formattedDate: string,
    date: Moment
  ) {
    const formattedTime = moment(date).format('HH:mm:ss')

    return `
        <div class="horiz dapp-detailed-stats-chart__tooltip">
                <div class="verti mx-2 text-white-500">
                    <span>
                         ${$.translate(
                           'components.DappDetailedStatsChart.date'
                         )}:
                    </span>
                    <span>${$.translate(
                      'components.DappDetailedStatsChart.time'
                    )}</span>
                    <span>${$.translate(
                      'components.DappDetailedStatsChart.txs'
                    )}</span>
                </div>
                 <div class="verti mx-2 text-white text-xs">
                    <span>${formattedDate}</span>
                    <span>${$.translate(
                      'components.DappDetailedStatsChart.timeUTC',
                      {
                        time: formattedTime,
                      }
                    )}</span>
                    <span>${transactionCount}</span>
                </div>
            </div>
    `
  }

  private formatTooltipForDays(
    transactionCount: number,
    formattedDate: string
  ) {
    return `
        <div class="horiz dapp-detailed-stats-chart__tooltip">
                <div class="verti mx-2 text-white-500">
                    <span>
                         ${$.translate(
                           'components.DappDetailedStatsChart.date'
                         )}:
                    </span>
                    <span>${$.translate(
                      'components.DappDetailedStatsChart.txs'
                    )}</span>
                </div>
                 <div class="verti mx-2 text-white text-xs">
                    <span>${formattedDate}</span>
                    <span>${transactionCount}</span>
                </div>
            </div>
    `
  }

  private calculateXAxisData24Hours() {
    const initialDate = moment()
      .minutes(0)
      .seconds(0)
      .subtract(24, 'hours')
    return Array.from({length: 24}).map(() => {
      const nextDate = initialDate.add(1, 'hour')
      return _.cloneDeep(nextDate)
    })
  }

  private calculateXAxisDataForDays() {
    const initialDateRemovingToday = moment().subtract(
      this.cache.daysRendered + 1,
      'days'
    )
    return Array.from({length: this.cache.daysRendered}).map(() => {
      const nextDate = initialDateRemovingToday.add(1, 'day')
      return _.cloneDeep(nextDate)
    })
  }
}
