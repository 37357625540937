












































































import {Component, Prop, Vue} from 'vue-property-decorator'
import _ from 'lodash'
import {TranslateResult} from 'vue-i18n'
import {InputSelect} from '@simpli/vue-input'
import {ContractCollection} from '@/model/collection/ContractCollection'
import {FilterContractClientSchema} from '@/schema/resource/Contract/FilterContractClientSchema'
import {$} from '@/facade'
import FilterContractClientFiltered from '@/components/filters/FilterContractClientFiltered.vue'
import FilterDappMobile from '@/components/filters/FilterDappMobile.vue'
import {DappCollection} from '@/model/collection/DappCollection'

export type SortOption = {
  $id: number
  field: string
  asc: boolean
  $tag: TranslateResult
}

export const getSortOptions = (): SortOption[] => [
  {
    $id: 1,
    field: 'title',
    asc: true,
    $tag: $.t('schema.FilterContract.nameLow'),
  },
  {
    $id: 2,
    field: 'title',
    asc: false,
    $tag: $.t('schema.FilterContract.nameHigh'),
  },
  {
    $id: 3,
    field: 'deploymentDate',
    asc: false,
    $tag: $.t('schema.FilterContract.dateDeployedRecentFirst'),
  },
  {
    $id: 4,
    field: 'deploymentDate',
    asc: true,
    $tag: $.t('schema.FilterContract.dateDeployedRecentLast'),
  },
  {
    $id: 5,
    field: 'transactionCountCurrentWeek',
    asc: true,
    $tag: $.t('schema.FilterContract.transactionLow'),
  },
  {
    $id: 6,
    field: 'transactionCountCurrentWeek',
    asc: false,
    $tag: $.t('schema.FilterContract.transactionHigh'),
  },
  {
    $id: 7,
    field: '7days',
    asc: true,
    $tag: $.t('schema.FilterContract.daysLow'),
  },
  {
    $id: 8,
    field: '7days',
    asc: false,
    $tag: $.t('schema.FilterContract.daysHigh'),
  },
]

@Component({
  components: {
    FilterDappMobile,
    FilterContractClientFiltered,
    InputSelect,
  },
})
export default class FilterContractClient extends Vue {
  @Prop({type: Object, required: true}) collection!: ContractCollection
  @Prop({type: Object, required: true}) sorterField!: SortOption

  sorterFieldMutation = this.sorterField
  showModal: boolean = false
  schema = new FilterContractClientSchema()
  sortOptions: SortOption[] = getSortOptions()

  async created() {
    const [, selectedOption] = this.sortOptions
    this.sorterFieldMutation = _.cloneDeep(selectedOption)
    await this.populateFilterOptions()
  }

  async populateFilterOptions() {
    this.sorterFieldMutation = this.sorterField
    this.collection.resource = this.schema

    const promises: Array<Promise<any>> = [
      this.collection.resource.collectionBlockchainVersion.queryAsPage(),
    ]

    await this.$await.run('softQuery', () => Promise.all(promises))
  }

  async doFilter() {
    this.$emit('submit')
    await this.$await.run('softQuery', () => this.collection.queryToFirstPage())
  }

  doClear() {
    this.collection.dapp = []
    this.collection.blockchainVersion = null
    this.doFilter()
  }

  onSortChange() {
    this.collection.setOrderBy(this.sorterFieldMutation.field)
    this.collection.setAsc(this.sorterFieldMutation?.asc || false)
    this.collection.setCurrentPage(0)
    this.doFilter()
  }

  private toggleModal(): void {
    this.showModal = !this.showModal
  }
}
