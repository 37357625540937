/**
 * Dapp Hourly Cache
 * @author Lopes
 */
import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {Dapp} from '@/model/resource/Dapp'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {DappHourlyCacheCollection} from '@/model/collection/DappHourlyCacheCollection'

export class DappHourlyCache implements IResource {
  idDappHourlyCachePk: number = 0

  @ResponseSerialize(Dapp)
  dapp: Dapp | null = null

  transactionCount: number = 0
  totalTransactionCount: number | null = null
  totalTransactionCountLastHour: number | null = null
  totalTransactionCountLastDay: number | null = null

  createdAt: string | null = null
  createdAtHourStart: string | null = null

  get $id() {
    return this.idDappHourlyCachePk
  }

  get $tag() {
    return String(this.$id)
  }

  /**
   * Lists the instances of DappHourlyCache
   */
  static async listDappHourlyCache(params: any) {
    return await Request.get(`/client/dapp-hourly-cache`, {params})
      .name('listDappHourlyCache')
      .as(DappHourlyCacheCollection)
      .getData()
  }
}
