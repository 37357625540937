/**
 * Contract Hourly Cache
 * @author Lopes
 */
import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {Contract} from '@/model/resource/Contract'

export class ContractHourlyCache implements IResource {
  idContractHourlyCachePk: number = 0

  @ResponseSerialize(Contract)
  contract: Contract | null = null

  transactionCount: number = 0
  totalTransactionCount: number | null = null
  totalTransactionCountLastHour: number | null = null
  totalTransactionCountLastDay: number | null = null

  createdAt: string | null = null
  createdAtHourStart: string | null = null

  get $id() {
    return this.idContractHourlyCachePk
  }

  get $tag() {
    return String(this.$id)
  }

  /**
   * Lists the instances of ContractHourlyCache
   */
  static async listContractHourlyCache(params: any | null = null) {
    return await Request.get(`/client/contract-hourly-cache/`, {
      params,
    })
      .name('getContractHourlyCache')
      .asArrayOf(this)
      .getData()
  }
}
