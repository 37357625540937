/**
 * Filter Schema of Contract
 * @author Simpli CLI generator
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {IContractCollectionResourcesHolder} from '@/model/collection/ContractCollection'
import {Contract} from '@/model/resource/Contract'
import {DappCollection} from '@/model/collection/DappCollection'
import {BlockchainVersionCollection} from '@/model/collection/BlockchainVersionCollection'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'
import {$} from '@/facade'
import {EnvHelper} from '@/helpers/EnvHelper'
import {PriceHistoryCollection} from '@/model/collection/PriceHistoryCollection'
import {ContractNep17Collection} from '@/model/collection/ContractNep17Collection'

export class FilterContractClientSchema extends DefaultSchema
  implements IContractCollectionResourcesHolder {
  collectionDapp = new DappCollection().noPagination()
  collectionBlockchainVersion = new BlockchainVersionCollection().noPagination()
  collectionPriceHistory = new PriceHistoryCollection().noPagination()
  collectionContractNep17 = new ContractNep17Collection().noPagination()

  readonly name = 'FilterContract'

  get blockchainVersionItems(): BlockchainVersion[] {
    const anyVersion = new BlockchainVersion()
    anyVersion.title = $.t('schema.FilterDapp.anyVersion') as string

    return [anyVersion, ...this.collectionBlockchainVersion.items]
  }

  readonly fieldSet: FieldSet<Contract> = {
    dapp: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        items: this.collectionDapp.items,
        multiple: true,
        closeOnSelect: false,
        preserveSearch: true,
        label: this.translateFrom(schema.fieldName),
        trackBy: '$id',
        preselectFirst: true,
      },
    }),

    blockchainVersion: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        items: this.blockchainVersionItems,
        hideSelected: false,
        closeOnSelect: false,
        preserveSearch: true,
        placeholder: this.translateFrom(schema.fieldName),
        trackBy: '$id',
        preselectFirst: true,
        class: {hidden: EnvHelper.VUE_APP_N3_HIDE},
      },
    }),
  }
}
