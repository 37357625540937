







































import {Component, Prop} from 'vue-property-decorator'
import {Dapp} from '@/model/resource/Dapp'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component
export default class DappDetailedLogo extends MixinScreenSize {
  @Prop({type: Dapp, required: true}) dapp!: Dapp
  @Prop({type: Boolean}) showVersion?: boolean
}
